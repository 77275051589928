import React, { useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/module/Hero";

import banner from "../assets/image/inner-page/png/modules/counsellor-module-banner.png";
import Functionalities from "../sections/module/Functionalities";
import {
  BenefitData,
  FeatureData,
  FunctionalitiesData,
} from "../const/static-data";
import Features from "../sections/module/Features";
import Benefits from "../sections/module/Benefits";
import SectionTabs from "../sections/module/SectionTabs";
import ModuleImage from "../sections/module/ModuleImage";

const CounsellorModule = () => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const withoutSlashesPath = currentPath.replace(/^\/+|\/+$/g, "").trim();
    const newPath = `/${withoutSlashesPath}/index.html`;
    window.history.replaceState({}, "", newPath);
  }, []);
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Start 14 Days Free Trial
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero title={"Counsellor"} banner={banner} />

        <SectionTabs />

        <section className="mt-15">
          <div className="container">
            <h2 className="font-size-8">About</h2>
            <p id="section2" className="gray-text font-size-6">
              This module lets you manage student requests, enrollment records,
              and prospective student tracking all from a single interface.
              Complex enrollment, eligibility, Counselling, and completion
              processes are streamlined and made easier with the{" "}
              <span className="title mr-1">UNICLOUD360</span>
              Counselor Module. By enabling its value-adding capabilities on any
              device, it serves as a crucial engagement tool. Through this
              module, every student will have the chance to engage meaningfully
              with your education center. The module will ensure that your
              enrollment and retention rates for students soar daily by setting
              up appointments, answering questions, and keeping track of pupils.
            </p>
          </div>
        </section>
        <Functionalities data={FunctionalitiesData.Counsellor} />
        <Features data={FeatureData.Counsellor} />
        <ModuleImage />
        <Benefits data={BenefitData.Counsellor} />
        {/*<Team />*/}
        {/*<Stats />*/}
      </PageWrapper>
    </>
  );
};
export default CounsellorModule;
